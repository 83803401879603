<template>
    <ul>
        <li v-for='(value,name,index) in errors'>
            {{ value }}
        </li>
    </ul>
</template>
<script>
export default {
    props: ['errors']
}
</script>
